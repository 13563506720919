import React from "react"
import styled from "styled-components"

const Container = styled.footer`
  font-size: 18px;
  display: block;
  text-align: center;
`

const Footer = () => {
  return (
    <Container>
      © {new Date().getFullYear()}, Edu Employee | All Rights Reserved
    </Container>
  )
}

export default Footer
