import React from "react"
import styled from "styled-components"
import GlobalStyle from "../styles/GlobalStyle"
import logo from "../images/logo.png"

const NavLinks = styled.nav`
  border: 0;
  display: block;
  width: 100%;
  height: 30px;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
  background: #F8E2F1;
  border-left: 2px solid #ccc;
  border-top: 4px solid #ccc;
  border-bottom: 4px solid #ccc;
  font-family: "Georgia";

  ul {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    text-align: center;
  }

  li {
    font-size: 15px;
    border-right: 2px solid #ccc;
    width: calc(100% / 5);
    text-align: center;
    cursor: pointer;
    height: 26px;

    @media (max-width: 360px) {
      font-size: 12px;
    }
  }

  
    li:nth-child(1) {
      &:hover {background-color: #d54062;}
    }

    li:nth-child(2) {
      &:hover {background-color: #ffa36c;}
    }

    li:nth-child(3) {
      &:hover {background-color: #ebdc87;}
    }

    li:nth-child(4) {
      &:hover {background-color: #799351;}
    }

    li:nth-child(5) {
      &:hover {background-color: #00b7c2;}
    } 
  }

  a {
    display: block;
    color: inherit;
  }
`

const Header = styled.div`
  text-align: center;
  margin-top: 0.5em;

  img {
    height: 50px;
  }
`

const Navbar = () => {
  return (
    <>
      <GlobalStyle />
      <Header>
        <a href="/">
          <img src={logo} alt="logo"></img>
        </a>
      </Header>
      <NavLinks>
        <ul>
          <li>
            <a href="/platforms">Platforms</a>
          </li>
          <li>
            <a href="/books">Books</a>
          </li>
          <li>
            <a href="/insights">Insights</a>
          </li>
          <li>
            <a href="/courses">Courses</a>
          </li>
          <li>
            <a href="/gear">Gear</a>
          </li>
        </ul>
      </NavLinks>
    </>
  )
}

export default Navbar
