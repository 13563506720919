import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
    font-family: "Roboto", sans-serif;
  }

body {
  height: 100%;
  font-size: 22px;
  line-height: 1.6;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

a {
  text-decoration: none;}
`

export default GlobalStyle
