import styled from "styled-components"

const FullContainer = styled.div`
  text-align: center;
  min-height: 100vh;

  @media (max-width: 1200px) {
    h1 {
      font-size: 36px;
    }
  }

  @media (max-width: 700px) {
    h1 {
      font-size: 34px;
    }
  }

  @media (max-width: 600px) {
    h1 {
      font-size: 24px;
    }
  }
`

export default FullContainer
